import React, { Suspense, lazy } from "react";
//import _config from './config'
import { AuthContextProvider } from "./contexts/AuthContext";
import { UserMetadataContextProvider } from "./contexts/UserMetadataContext";
import { PdfFocusProvider } from "./contexts/pdf";
import CssBaseline from "@mui/material/CssBaseline";
import * as Sentry from "@sentry/react";
import * as Redux from "react-redux";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import LoggedIn from "./components/Access/LoggedIn";
import Admin from "./components/Access/Admin";
import Demo from "./components/Access/Demo";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ErrorPage from "pages/Error/Error";
import { store } from "store/index";

import { useMediaQuery } from "@mui/material";
import { Provider } from "react-redux";

const GoodBye = lazy(() => import("pages/LandingPage/GoodBye"));

const ProjectsView = lazy(() => import("pages/Projects/ProjectsView"));
const LibraryView = lazy(() => import("pages/Library/LibraryView"));
const LibraryUpload = lazy(() => import("pages/Library/LibraryUpload"));
const LibraryUploadIntegrations = lazy(() =>
  import("pages/Library/LibraryUploadIntegrations")
);
const LibraryDocumentView = lazy(() =>
  import("pages/Library/LibraryDocumentView")
);
const RequestsView = lazy(() => import("pages/Requests/RequestsView"));
const RequestsCreate = lazy(() => import("pages/Requests/RequestsCreate"));
const RequestsManage = lazy(() => import("pages/Requests/RequestsManage"));
const RequestsViewDetails = lazy(() =>
  import("pages/Requests/RequestsViewDetails")
);
const RequestsViewInsights = lazy(() =>
  import("pages/Requests/RequestsViewInsights")
);

const ProjectViewDetails = lazy(() =>
  import("pages/Projects/ProjectViewDetails")
);
const SourcesViewDetails = lazy(() =>
  import("pages/Sources/SourcesViewDetails")
);
const WelcomeUser = lazy(() => import("./pages/LandingPage/WelcomeUser"));
const MainFrame = lazy(() => import("./pages/MainFrame/MainFrame"));
const Profile = lazy(() => import("./pages/Account/Profile"));
const AdminPanel = lazy(() => import("./pages/Account/AdminPanel"));
const SignIn = lazy(() => import("./pages/SignIn/SignIn"));

const customTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2C71F0",
    },
    secondary: {
      main: "#ECF1FE",
      contrastText: "#2C71F0",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      secondary: "#2C71F0",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },

  /*components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            "linear-gradient(90deg, white calc(20px - 1px), transparent 1%) center / 20px 20px, linear-gradient(white calc(20px - 1px), transparent 1%) center / 20px 20px black",
        },
      },
    },
  },*/
});

export default function App() {
  //const matches = useMediaQuery("(min-width:600px)");

  //console.log(`(min-width:600px) matches: ${matches}`);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <LoggedIn>
          <MainFrame />
        </LoggedIn>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          children: [
            { index: true, element: <WelcomeUser /> },
            {
              path: "/goodbye",
              exact: true,
              element: (
                <Demo>
                  {" "}
                  <GoodBye />
                </Demo>
              ),
            },
            {
              path: "/profile",
              exact: true,
              element: <Profile />,
            },
            {
              path: "/admin",
              exact: true,
              element: (
                <Admin>
                  <AdminPanel />
                </Admin>
              ),
            },
            {
              path: "/projects/view",
              exact: true,
              element: <ProjectsView />,
            },
            {
              path: "/projects/:id/view",
              exact: true,
              element: <ProjectViewDetails />,
            },
            {
              path: "/library/view",
              exact: true,
              element: (
                <Demo>
                  <LibraryView />
                </Demo>
              ),
            },
            {
              path: "/library/:documentId/view",
              exact: true,
              element: (
                <Demo>
                  <LibraryDocumentView />
                </Demo>
              ),
            },
            {
              path: "/library/upload",
              exact: true,
              element: (
                <Demo>
                  <LibraryUpload />
                </Demo>
              ),
            },
            {
              path: "/library/upload-integrations",
              exact: true,
              element: (
                <Demo>
                  <LibraryUploadIntegrations />
                </Demo>
              ),
            },
            {
              path: "/requests/view",
              exact: true,
              element: (
                <Demo>
                  <RequestsView />
                </Demo>
              ),
            },
            {
              path: "/requests/manage",
              exact: true,
              element: (
                <Demo>
                  <RequestsManage />
                </Demo>
              ),
            },
            {
              path: "/requests/:id/view",
              exact: true,
              element: (
                <Demo>
                  <RequestsViewDetails />
                </Demo>
              ),
            },
            {
              path: "/requests/:id/insights",
              exact: true,
              element: (
                <Demo>
                  <RequestsViewInsights />
                </Demo>
              ),
            },
            {
              path: "/sources/:projectId/:sourceId/view",
              exact: true,
              element: (
                <Demo>
                  <SourcesViewDetails />
                </Demo>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "/signin",
      exact: true,
      element: <SignIn redirectTo="/welcome" />,
      errorElement: <div>Error</div>,
    },
  ]);
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Redux.Provider store={store}>
        <AuthContextProvider>
          <UserMetadataContextProvider>
            <Suspense fallback={<div />}>
              <RouterProvider router={router} />
            </Suspense>
          </UserMetadataContextProvider>
        </AuthContextProvider>
      </Redux.Provider>
    </ThemeProvider>
  );
}
