import React, { useContext } from "react";
import { createContext, useState, useEffect } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase";

//the context we'll be accessing accross the app
const AuthContext = createContext();

//the provider of the context, where the magic happens
export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [pending, setPending] = useState(true);

  const [userMetaData, setUserMetaData] = useState({});

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  const signInWithEmail = (email, pwd) => {
    signInWithEmailAndPassword(auth, email, pwd);
  };

  const logOut = () => {
    signOut(auth);
  };

  async function getUserMetaData(setPlan, token) {
    await fetch(process.env.REACT_APP_BACKEND_API + "/getusermetadata", {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((response) => {
        setUserMetaData(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        getUserMetaData(setUserMetaData, currentUser.accessToken);
      }
      setUser(currentUser);
      setPending(false);
    });
    return unsubscribe;
  }, []);

  // Function to update user metadata
  const updateUserMetaData = async () => {
    if (user) {
      await getUserMetaData(setUserMetaData, user.accessToken);
    }
  };
  if (pending) {
    return <></>;
  }
  return (
    <AuthContext.Provider
      value={{
        user,
        userMetaData,
        signInWithGoogle,
        signInWithEmail,
        logOut,
        updateUserMetaData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

//additional function to avoid saying use context use auth each time
//probably useful for when you have multiple context variables
export const UserAuth = () => {
  return useContext(AuthContext);
};
