import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "../../contexts/AuthContext";

function LoggedIn({ children }) {
  const { user, authStatus } = UserAuth();
  const location = useLocation();
  if (user) {
    return children;
  } else {
    return (
      <Navigate
        to={{
          pathname: "/signin",
          search: `from=${location.pathname}`,
          state: { from: location },
        }}
        replace
      />
    );
  }
}

export default LoggedIn;
