import React from "react";
import { render } from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

/*Sentry.init({
  //This is safe to keep public : https://docs.sentry.io/product/sentry-basics/concepts/dsn-explainer/
  dsn: "https://61e8846ade54fab874c290a971c2c3e3@o4506676634386432.ingest.sentry.io/4506676677967872",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "/^https://steerlab-backend-prod.com/",
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});*/

render(<App />, document.getElementById("root"));
