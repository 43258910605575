import { React, lazy } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "../../contexts/AuthContext";
const PageNotFound = lazy(() => import("../PageNotFound/PageNotFound"));

function Admin({ children }) {
  const { user, userMetaData } = UserAuth();
  const location = useLocation();
  if (user) {
    if (Object.keys(userMetaData).length === 0) {
      return <div>Loading...</div>;
    } else {
      if (userMetaData.role == "admin" || userMetaData.role == "demo") {
        return children;
      } else {
        return <PageNotFound />;
      }
    }
  } else {
    return (
      <Navigate
        to={{
          pathname: "/signin",
          search: `from=${location.pathname}`,
          state: { from: location },
        }}
        replace
      />
    );
  }
}

export default Admin;
