import fromBackendDocumentToFrontend from "./utils";

export const createProject = async (user, requestData) => {
  const url = `rag/project`;
  await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
};

export const initProjectEnvironment = async (user, projectName) => {
  const url = `rag/init-environment/${projectName}`;

  await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
};

export const chunkIt = async (user, requestData) => {
  const url = `rag/chunker`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
  return promise;
};

export const askQuestion = async (user, requestData) => {
  const url = `rag/question`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
  return promise;
};

export const runQuery = async (user, requestData) => {
  const url = `rag/query`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
  return promise;
};

export const runMultipleQueries = async (user, requestData) => {
  var arrayPromises;
  if (requestData.retriever_config.option == "simple") {
    //run query as is, return one promise
    const promise_simple = runQuery(user, requestData);
    arrayPromises = [promise_simple, promise_simple, promise_simple];
  } else if (requestData.retriever_config.option == "rerank") {
    const promise_rerank = runQuery(user, requestData);

    //Let's also call simple one
    const newRetrieverConfig = {
      ...requestData.retriever_config,
      option: "simple",
    };
    const newRequestData = {
      ...requestData,
      retriever_config: newRetrieverConfig,
    };
    const promise_simple = runQuery(user, newRequestData);

    arrayPromises = [promise_simple, promise_rerank, promise_rerank];
  } else if (requestData.retriever_config.option == "fusion") {
    const promise_fusion = runQuery(user, requestData);

    //Let's also call simple one
    const RetrieverConfigSimple = {
      ...requestData.retriever_config,
      option: "simple",
    };
    const RequestDataSimple = {
      ...requestData,
      retriever_config: RetrieverConfigSimple,
    };
    const promise_simple = runQuery(user, RequestDataSimple);

    //Let's also call rerank one
    const RetrieverConfigRerank = {
      ...requestData.retriever_config,
      option: "rerank",
    };
    const RequestDataRerank = {
      ...requestData,
      retriever_config: RetrieverConfigRerank,
    };
    const promise_rerank = runQuery(user, RequestDataRerank);

    arrayPromises = [promise_simple, promise_rerank, promise_fusion];
  }

  const promiseAll = Promise.all(arrayPromises);

  return promiseAll;
};

export const embedIt = async (user, requestData) => {
  const url = `rag/embedder`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
  return promise;
};

export const getProjectDetails = async (user, projectId) => {
  const url = `rag/projects/${projectId}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};

export const getProjects = async (user) => {
  console.log("get projects api used for store redux ");
  const url = `rag/projects`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};

export const getUserProject = async (user) => {
  const url = `rag/user-project/`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};

export const listDocuments = async (user, projectId) => {
  const url = `library/${projectId}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};

export const getDocument = async (user, projectId, documentId) => {
  const url = `library/${projectId}/${documentId}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};

export const uploadDocument = async (user, file, projectId, requestData) => {
  const url = `library/${projectId}/upload`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("rowData", requestData.rowData);
  formData.append("columnDefs", requestData.columnDefs);
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  });
  return promise;
};
export const updateDocument = async (user, file, projectId, requestData) => {
  const url = `library/${projectId}/update`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("rowData", requestData.rowData);
  formData.append("columnDefs", requestData.columnDefs);

  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  });
  return promise;
};

export const listRequests = async (user, projectId) => {
  const url = `requests/${projectId}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};

export const getRequest = async (user, projectId, requestId) => {
  const url = `requests/${projectId}/${requestId}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};
export const getRequestData = async (user, projectId, requestId) => {
  const url = `requests/data/${projectId}/${requestId}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};
export const uploadRequest = async (user, file, projectId, requestData) => {
  const url = `requests/${projectId}/upload`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("rowData", requestData.rowData);
  formData.append("columnDefs", requestData.columnDefs);

  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  });
  return promise;
};

export const deleteRequest = async (user, projectId, requestId) => {
  const url = `requests/${projectId}/${requestId}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  });
  return promise;
};

//#to-do: maybe we need to simplify the routes, i don't like the sources and sources/data
export const getSource = async (user, projectId, sourceId) => {
  const url = `sources/${projectId}/${sourceId}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};

export const getSourceData = async (user, projectId, sourceId) => {
  const url = `sources/data/${projectId}/${sourceId}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};
export const deleteInsight = async (user, requestData) => {
  const url = `insights/${requestData.insight_id}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  });
  return promise;
};

export const deleteDocument = async (user, project_id, document_id) => {
  const url = `library/${project_id}/${document_id}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  });
  return promise;
};

export const changeStyle = async (user, requestData) => {
  const url = `tasks/change-style`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
  return promise;
};

export const retrieveQandA = async (user, requestData) => {
  const url = `rag/qanda`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
  return promise;
};

export const fetchConversation = async () => {
  //placeholder for a fake fetch conversation
  const fakeResponse = { messages: {}, documents: {} };
  return {
    messages: fakeResponse.messages,
    documents: fromBackendDocumentToFrontend(fakeResponse.documents),
  };
};

export const fetchMessage = async () => {
  //placeholder for a fake fetch message
  return {};
};

export const saveFeedback = async (user, requestData) => {
  const url = `rag/user-feedback`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
  return promise;
};

export const saveLabelingFeedback = async (user, requestData) => {
  const url = `rag/our-labeling-feedback`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
  return promise;
};

export const deleteIndex = async (user, index_name) => {
  const url = `admin/indices/${index_name}`;
  const promise = await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  }).catch((error) => {
    console.log("error", error);
  });
  return promise;
};

export const createIndex = async (user, requestData) => {
  const url = `admin/index`;
  await fetch(`${process.env.REACT_APP_BACKEND_API}/` + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
};
