import React, { useContext } from "react";
import { createContext, useState, useEffect } from "react";
import { UserAuth } from "./AuthContext";
import { getUserProject } from "services/api";
//the context we'll be accessing accross the app
const UserMetadataContext = createContext();

//the provider of the context, where the magic happens
export function UserMetadataContextProvider({ children }) {
  const [credits, setCredits] = useState("");
  const { user, userMetaData } = UserAuth();
  const [defaultWorkspace, setDefaultWorkspace] = useState("");
  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const response = await getUserProject(user);
        const data = await response.json();
        setDefaultWorkspace(data.project_id);
      } catch (err) {
        console.log("error", err);
      }
    };
    if (Object.keys(userMetaData).length != 0) {
      setCredits(userMetaData.maxUsage - userMetaData.currentUsage);
    }
    fetchWorkspace();
  }, [userMetaData]);

  return (
    <UserMetadataContext.Provider
      value={{ credits, setCredits, defaultWorkspace }}
    >
      {children}
    </UserMetadataContext.Provider>
  );
}

//additional function to avoid saying use context use auth each time
//probably useful for when you have multiple context variables
export const UserMeta = () => {
  return useContext(UserMetadataContext);
};
