import { Reducer, createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

export const selectors = {
  getData: (state: State) => state.value,
};

export const RESET = "user/RESET";
export const LOAD = "user/LOAD_DATA";

type Value = string | boolean | number | undefined;

export type State = {
  value?: Value;
  companyOnly?: Value;
};

export type Action =
  | { type: "user/RESET" }
  | {
      type: "user/LOAD_DATA";
      data: Value;
    };

const init: State = {};

export const reducer: Reducer<State, Action> = (state = init, action) => {
  switch (action.type) {
    case LOAD:
      return { ...state, value: action.data };
    case RESET:
      return {};
    default:
      return state;
  }
};

const saveLocal = (store: any) => (next: any) => (action: any) => {
  const value = next(action);
  return value;
};

const composer = composeWithDevTools(applyMiddleware(thunk, saveLocal));

export const store = createStore(reducer, composer);
